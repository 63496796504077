<template>
	<div class="d-flex align-start justify-center" style="height:100%; overflow: hidden;">
		<v-card class="my-3 d-flex flex-column align-center justify-center mt-12">
            <v-card-title>Emargement</v-card-title>
			<v-card-text class="d-flex flex-column align-center justify-center">
                <div class="d-flex flex-column align-center justify-center" v-if="profile && signature && room">
                    <span v-text="profile.lastname + ' ' + profile.firstname"></span>
                    <span v-text="profile.email"></span>
                    <span v-text="room.name"></span>
                    <span v-text="new Date(signature.date).toLocaleString('fr-FR', { timeZone: 'Europe/Paris' }).slice(0, 16)"></span>
                </div>
                <span class="py-3" style="width:100%">
                    <v-divider></v-divider>
                </span>
                <div id="container"></div>
                <span class="py-3" style="width:100%">
                    <v-divider></v-divider>
                </span>
                <span class="pt-3">
                    <v-btn color="success" outlined class="mx-2" @click="saveSignature">Valider</v-btn>
                    <v-btn color="error" outlined class="mx-2" @click="eraseSignature">Effacer</v-btn>
                </span>
			</v-card-text>
		</v-card>
    </div>        
</template>

<script>
    import { deleteObject, getStorage, ref, uploadBytes } from "firebase/storage"
    import Swal from 'sweetalert2/dist/sweetalert2.js'

    import downloader from "@/assets/functions/downloader.js"

    import Signature from "@/classes/Signature.js"
    import Profile from "@/classes/Profile.js"
    import Room from "@/classes/Room.js"

	export default {
		name: "Signing",
		props: [],
        setup() {
            return {
                storage: getStorage()
            }
        },
        data() {
            return {
                unsub: [],
                p5: null,
                p5Canvas: null,
                profileId: this.$route.params.profileId,
                signatureId: this.$route.params.signatureId,
                profile: null,
                signature: null,
                room: null,
            }
        },
        created() {
            this.unsub.push(
                Profile.listenById(this.profileId,(profile) => {
                    this.profile = profile
                }),
                Signature.listenById(this.signatureId,async (signature) => {
                    this.signature = signature
                    this.room = await Room.getById(signature.room)
                })
            )
        },
        mounted() {
            //P5
            this.p5 = new p5((p) => {
                p.setup = () => {
                    this.p5Canvas = p.createCanvas(500, 300)
                    p.background(255)
                }
                p.draw = () => {
                    if (p.mouseIsPressed) {
                        p.stroke(0)
                        p.strokeWeight(2)
                        p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY)
                    }
                }
                p.eraseSignature = () => {
                    p.background(255)
                }
            },document.querySelector('#container'))

            document.querySelector('#container').addEventListener('touchmove', (e) => {
                e.preventDefault()
            })
        },
        methods: {
            eraseSignature(){
                this.p5.eraseSignature()
            },
            async saveSignature(){
                let currentTimeStamp = new Date().getTime()

                let fileName = this.profile.id+"_Signature.png"
                let filePath = "signature/"+this.room.id+"/"+this.signature.id+"/"+fileName
                let fileRef = ref(this.storage, filePath)

                this.p5Canvas.canvas.toBlob(async (blob) => {
                    await uploadBytes(fileRef, blob)
                
                    let tmp_signatureObject = {
                        filePath: filePath,
                        date: currentTimeStamp,
                        status: "Present",
                        adminStatus: null,
                    }

                    if(this.signature.date + this.signature.tolerance < currentTimeStamp){
                        tmp_signatureObject.status = "Late"
                    }

                    this.signature.signatures[this.profile.id] = tmp_signatureObject
                    await this.signature.save()
                    
                    Swal.fire({
                        title: "Présence enregistrée !",
                        text: "Votre présence a bien été enregistrée !",
                        icon: "success",
                        confirmButtonText: "Ok",
                    }).then(() => {
                        this.$router.push({name: "home"})
                    })
                })
            }
        },
        destroyed() {
            this.unsub.forEach((unsub) => unsub())
        },
	}
</script>
